body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Customizes the scrollbar to be thinner, sleeker */
::-webkit-scrollbar {
  width: 6px; /* Slimmer scrollbar */
  height: 6px; /* For horizontal scrollbars */
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); /* Subtle track color */
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2); /* Darker thumb color for contrast */
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3); /* Slightly darker on hover for visual feedback */
}
