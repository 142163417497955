// @import './reset.css';

@import '~antd/dist/antd.less';

@import '//at.alicdn.com/t/c/font_3823694_agnnx51apl4.css';

@import url('https://fonts.googleapis.com/css2?family=Albert+Sans:wght@300;400;500;600;700&display=swap');

@font-family: 'Albert Sans', 'SF Pro Text', arial, sans-serif, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';


// breakpoint width
//     xs: '480px',
//     sm: '576px',
//     md: '768px',
//     lg: '992px',
//     xl: '1200px',
//     xxl: '1600px',
// .mainBg {
//   background: linear-gradient(180deg, #dae3f1 0%, #ffffff 100%);
//   border: 1px solid #ffffff;
// }

body {
  padding: 0;
  margin: 0;
}
// .mainTableNav {
//   background: linear-gradient(180deg, #dae3f1 0%, #ffffff 100%);
//   border: 1px solid #ffffff;
//   border-radius: 6px;
//   padding: 16px;

//   .title {
//     font-weight: 400;
//     font-size: 14px;
//     line-height: 18px;
//     color: #385e9d;
//     padding-bottom: 2px;
//     border-bottom: 1px solid #385e9d;
//   }

//   .table {
//     margin-top: 23px;
//   }
// }
//antd 全局覆盖样式
.ant-btn {
  padding: 0;
  border: none;
}


.btn-234 {
  border-radius: 8px;
  background-color: #ec7f25;
  padding: 5px 44px;
  font-size: 14px;
  color: #fff;
  height: 33px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}


.input-234 {
border-radius: 10px;
height: 42px;
border-width: 1.5px;
border-color: #bababa;
font-size: 13px;

&::placeholder {
  color: #6f6f6f;
}
}


.form-item-234 {
font-size: 14px;
font-weight: 600;

input {
  .input-234;
}

textarea {
  .input-234;
  height: 156px;
}

.ant-input-number {
  .input-234;
}

}


.delete-modal-234 {
  
  .ant-modal-content {
    border-radius: 30px;
    border: #B1B1B1 1.5px solid;
  }
  .ant-modal-body {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
    padding-top: 25px;
    
    .ant-modal-confirm-btns {
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: row-reverse;
      >:first-child {
        width: 150px;
        background-color: #EC7F25;
        border: #EC7F25 1px solid;
        color: #fff;
        margin-left: 10px;
        border-radius: 8px;
      }
      >:last-child {
        width: 150px;
        border: #BDBDBD 1px solid;
        background-color: #fff;
        color: #525252;
        margin-right: 10px;
        border-radius: 8px;
      }
    }
  }

}
@primary-color: #EC7F25;